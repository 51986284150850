<template lang="pug">
	.popup-container
		.overlay(v-on:click="hide()")
		.popup
			.close(v-on:click="hide()")
			.inside
				h6
					span Step 1: 
					| Open "Settings" menu in your LinkedIn Account.
					br
					span Step 2: 
					| Click "Data privacy" from the left menu.
					br
					span Step 3: 
					| Find "Other applications" section from the right menu and select "Permitted Services".
					br
					span Step 4: 
					| Click "Remove" next to the INFUSE service to disconnect your LinkedIn account.

				h6 If you experience further issues, for any additional assistance, please contact your INFUSE Client Success Representative.

		
</template>
<script>
	export default {
		name: 'Help',
		methods: {
			hide(){
				this.$emit('close');
			}
		}
	}
</script>