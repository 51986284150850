<template lang="pug">
	.instruction-page
		.container
			.row
				.col-lg-8.offset-lg-2
					h6 Welcome to your exclusive access to INFUSE Demand Intelligence dashboard. Once you authorise INFUSE to access your LinkedIn Ad Account data, you will have access to a unique dashboard where your LinkedIn campaign data is displayed with all of your INFUSE campaign data, helping you to prioritise and personalise account level sales approaches.

					h6 Please have the LinkedIn credentials ready for your organisation's LinkedIn Ad Account. Do note that our integration is compliant with all data and privacy guidelines set out by LinkedIn and GDPR. We will only use the access to your LinkedIn campaign data for displaying on the dashboard. For more information, please reach out to your INFUSE representative.

					h6
						span Step 1: 
						| Click "Connect", and you will be taken to your LinkedIn Authorization page. 
						br
						span Step 2: 
						| Click "Allow"  to allow INFUSE to connect with your LinkedIn campaigns.
						br
						span Step 3: 
						| Select the LinkedIn accounts you wish to integrate with INFUSE.
						br
						span Step 4: 
						| Expect to receive access to your dashboard shortly.

				.col-12.text-center
					a(
						href="https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=77tirkhhyaqxvg&redirect_uri=https://linkedin.infuse.com/accounts&scope=r_ads_reporting,r_basicprofile,r_ads,r_organization_admin"
					).c-btn.c-simple Connect

			.row.text-center
				.col-lg-12
					h6.show-popup(v-on:click="togglePopup()") How do I disconnect the integration?

		Help(
			v-bind:class="{show: showHelp}"
			@close="togglePopup"
		)
</template>

<script>
	export default {
		data() {
			return {
				showHelp: false
			}
		},
		methods: {
			togglePopup(){
				this.showHelp = !this.showHelp;
			}
		}
	};
</script>