import { defineStore } from 'pinia'

export const useAuthStore = defineStore('auth', {
	state: () => ({
		refreshToken: '',
		refreshTokenExp: '',
		accessToken: '',
		accessTokenExp: '',
		scopes: '',
		client_id: '',
		first_name: '',
		last_name: '',
		headline: ''
	}),
	persist: true,

	getters: {
		
	},

	actions: {
		setTokens(data){
			this.refreshToken = data.refresh_token;
			this.refreshTokenExp = this.calculateExpirationDate(data.refresh_token_expires_in);
			this.accessToken = data.access_token;
			this.accessTokenExp = this.calculateExpirationDate(data.expires_in);
			this.scopes = data.scope;
			this.client_id = data.client_id;
			this.first_name = data.first_name;
			this.last_name = data.last_name;
			this.headline = data.headline;
		},
		calculateExpirationDate(seconds) {
			const expirationDate = new Date();
			expirationDate.setSeconds(expirationDate.getSeconds() + seconds);
			return expirationDate.toLocaleString();
		},
	}
})
