<template lang="pug">
.accounts-page
	Error(v-if="errorMessage")
	Loader(v-if="!dataLoad")
	b-container(fluid)
		b-row.justify-content-center
			h4.text-white.text-center.my-3 Select Accounts

		b-row.mb-3
			b-col
				b-table(
					:items="items"
					:fields="fields"
					:per-page="perPage"
					:current-page="currentPage"
					@row-clicked="rowClicked"
					:tbody-tr-class="tbodyRowClass"
					primary-key="id"
					label-sort-asc=""
					label-sort-desc=""
					label-sort-clear=""
					responsive
					fixed
					sort-icon-left
					head-variant="dark"
				).transparent-table.mb-xxl-auto.full-width-table
					template(#cell(selected)="data")
						.row-state(v-bind:class="{checked: data.item.selected}")
							//- span(aria-hidden="true") &check;
							//- span.sr-only Selected
						
						//- template(v-else)
						//- 	span(aria-hidden="true") &nbsp;
						//- 	span.sr-only Not selected

					template(#cell(id)="data")
						span.fixed-width-id {{ data.item.id }}

					template(#cell(name)="data")
						span.fixed-width-name {{ data.item.name }}

		b-row.pagination-block
			b-col
				p.text-white Amount of items selected: {{ selectedRows.length }}

			b-col
				b-pagination(
					:per-page="perPage"
					v-model="currentPage"
					:total-rows="items.length"
					variant="dark"
					size="sm"
					align="center"
				).transparent-pag.mb-3

			b-col.d-flex.justify-content-end.mb-3
				input(
					type="button",
					:disabled="selectedRows.length === 0"
					value="Clear selection"
					@click="clearSelected"
				).c-btn.c-danger.mr

				input(
					type="button",
					value="Select all"
					@click="selectAll"
				).c-btn.c-simple

		b-row.justify-content-center.mt-3
			b-col(
				cols="auto"
			).d-flex.justify-content-center
				input(
					:disabled="selectedRows.length === 0"
					type="button",
					value="Share"
					@click="share"
				).c-btn.c-border
		b-row.mt-3.text-center
			b-col
				p.text-white
					b Important: To proceed with the integration, select the relevant accounts and click 'SHARE'.

</template>

<script>
import { BContainer, BRow, BCol, BPagination, BTable, BButton } from 'bootstrap-vue';
import { $api } from '@/api_client'
import { useAuthStore } from '@/store/auth'
import Loader from '@/components/loader'
import Error from '@/components/error'

export default {
	props: {
		code: String,
	},
	components: {
		BContainer,
		BRow,
		BCol,
		BPagination,
		BTable,
		BButton,
		Loader,
		Error
	},
	setup () {
		const authStore = useAuthStore()
		return { authStore }
	},
	data() {
		return {
			scopes: null,
			refreshToken: null,
			refreshTokenExp: null,
			accessToken: null,
			accessTokenExp: null,
			error: null,
			errorMessage: null,
			items: [],
			fields: [
				{ key: "selected", label: "", sortable: false, thStyle: { width: '50px' }, tdClass: 'align-middle'	},
				{ key: "id", label: "ID", sortable: true, thStyle: { width: '200px' }, },
				{ key: "name", label: "Name", sortable: true }
			],
			perPage: 5,
			currentPage: 1,
			dataLoad: false
		};
	},
	created() {
		if (this.code) {
			this.getRefreshToken();
		} else {
			this.error = 'Authorization code not found.';
		}
	},
	methods: {
		async getRefreshToken(){
			await $api.get('get_refresh_token.php?code=' + this.code).then((res) => {
				this.items = res.elements.map(account => (
				{
					id: account.id,
					name: account.name,
					selected: false
				}));
				this.dataLoad = true;
				this.authStore.setTokens(res)
			}).catch(() => {
				this.dataLoad = true;
				this.errorMessage = true;
				// setTimeout(() => {

				// });
			})
		},
		async getRefreshToken2() {
			try {
				const response = await fetch('https://bi-hook.infuse.com/linkedin/get_refresh_token.php?code=' + this.code);
				const data = await response.json();
				this.refreshToken = data.refresh_token;
				this.refreshTokenExp = this.calculateExpirationDate(data.refresh_token_expires_in);
				this.accessToken = data.access_token;
				this.accessTokenExp = this.calculateExpirationDate(data.expires_in);
				this.scopes = data.scope;
				this.items = data.elements.map(account => ({
					id: account.id,
					name: account.name,
					selected: false
				}));
			} catch (error) {
				this.error = 'Failed to obtain refresh token.';
				console.error('Failed to obtain refresh token', error);
			}
		},
		calculateExpirationDate(seconds) {
			const expirationDate = new Date();
			expirationDate.setSeconds(expirationDate.getSeconds() + seconds);
			return expirationDate.toLocaleString();
		},
		clearSelected() {
			this.selectedRows.forEach((item) => {
				this.$set(item, 'selected', false);
			});
		},
		selectAll() {
			this.items.forEach((item) => {
				this.$set(item, 'selected', true);
			});
		},
		tbodyRowClass(item) {
			return item.selected ? ["b-table-row-selected", "table-primary", "cursor-pointer"] : ["cursor-pointer"];
		},
		rowClicked(item) {
			this.$set(item, 'selected', !item.selected);
		},
		updateSelected(item) {
			this.$set(item, 'selected', !item.selected);
		},
		async share(){
			let selectedAccounts = this.items.filter(item => item.selected).map(item => item.id);
			let shareForm = {
				client_id: this.authStore.client_id,
				accounts: selectedAccounts,
				first_name: this.authStore.first_name,
				last_name: this.authStore.last_name,
				headline: this.authStore.headline,
			}
			await $api.post('set_shared_accounts.php', shareForm)
				.then(() => {
					this.$router.push({ name: 'ThankYou' })
				}).catch(() => {
					this.dataLoad = true;
					this.errorMessage = true;
				})
		}
	},
	computed: {
		selectedRows() {
			return this.items.filter(item => item.selected);
		}
	}
};
</script>

<style>
.transparent-table {
	width: 100% !important;
	background-color: transparent !important;
	color: white;
}

.transparent-pag {
	background-color: transparent !important;
}

.transparent-table .b-table thead th {
	background-color: transparent !important;
	color: white;
}

.transparent-table .b-table tbody td {
	background-color: transparent !important;
	color: white;
}

.transparent-table .b-table tbody tr {
	border-bottom: 1px solid white !important;
}

.b-table-row-selected {
	background-color: rgba(0, 123, 255, 0.5) !important;
}

.text-white {
	color: white !important;
}

.text-center {
	text-align: center !important;
}

.pagination-block {
	margin-top: 20px;
}

body {
	padding: 1rem;
}

.mt-3 {
	margin-top: 1rem !important;
}

.btn-block {
	width: 100%;
}
</style>
